import { useExperiment } from '@motion/web-common/flags'

export function useShouldShowSalesTaxMessage() {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  const countryCode = locale.split('-')[1]
  const intlTaxCountryCodes =
    useExperiment('international-sales-tax-countries').payload?.countryCodes ??
    []

  if (!countryCode) {
    return false
  }

  return intlTaxCountryCodes.includes(countryCode)
}
