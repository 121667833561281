import { entries, keys, values } from '@motion/utils/object'
import { type AllModelsSchema } from '@motion/zod/client'

import {
  type QueryClient,
  type QueryFilters,
  type QueryKey,
} from '@tanstack/react-query'

import { ARCHIVED_TIME_KEY } from './constants'
import { applyPartialToTargetStore } from './shared'
import {
  type ApplyModelReturn,
  type ModelRecord,
  type QueryCacheMatches,
  type QueryCacheUpsert,
} from './types'
import {
  getResponseModelType,
  matchQueries,
  shouldAppendNew,
  updateQueryData,
} from './utils'

import { updateIndexForModel } from '../indexes/index-manager'
import { isModelCacheKey, type Model, type ModelId } from '../model-cache'
import { type V2ResponseStoreShape } from '../types'
import { log } from '../utils'

/**
 * Upsert the cache with a **full** model response.
 * Appends new items by default
 *
 * @param client - The QueryClient instance to perform the upsert on.
 * @param filter - The QueryFilters to determine which queries to update.
 * @param response - The full response data to upsert into the cache.
 * @param appendNewFilter - Optional function to determine whether to append new data.
 *
 * @returns An array of QueryCacheUpsert objects representing the changes made.
 */
export function updateQueryCaches(
  client: QueryClient,
  filter: QueryFilters,
  response: V2ResponseStoreShape
): QueryCacheUpsert<keyof AllModelsSchema>[] {
  try {
    const queries = matchQueries(client, filter)

    const cacheUpdates: QueryCacheUpsert<keyof AllModelsSchema>[] = []

    log.time('update.all', () => {
      queries.forEach(([key, data]) => {
        if (data == null) return

        log.time('update', (): void => {
          const updates = processQueryCacheUpsert(client, data, response, key)
          cacheUpdates.push(...updates)
        })
      })
    })

    updateQueryData(client, cacheUpdates)
    return cacheUpdates
  } catch (error) {
    log.error('Error updating query caches:', error)
    return []
  }
}

function processQueryCacheUpsert(
  client: QueryClient,
  data: QueryCacheMatches,
  response: V2ResponseStoreShape,
  key: QueryKey
): QueryCacheUpsert<keyof AllModelsSchema>[] {
  const cacheUpdates: QueryCacheUpsert<keyof AllModelsSchema>[] = []

  const types = keys(response.models)
  types.forEach((type) => {
    const source = response.models[type]
    if (source == null) return

    const updates = applyMatching(data, source, type, key)

    cacheUpdates.push(...updates)

    values(source).forEach((model) => {
      updateIndexForModel(client, type, model, cacheUpdates)
    })
  })

  return cacheUpdates
}

/**
 * Apply the source models to the target store.
 *
 * Either an upsert or an update depending on `appendNew` and if the model already exists.
 */
function applyMatching<TType extends keyof AllModelsSchema>(
  target: QueryCacheMatches,
  source: Record<ModelId, Partial<Model<TType>>>,
  type: TType,
  key: QueryKey
): QueryCacheUpsert<TType>[] {
  const cacheUpdates: QueryCacheUpsert<TType>[] = []

  entries(source).forEach(([id, model]) => {
    const targetStore = target.models[type] as ModelRecord<TType>

    if (targetStore == null || id == null || model == null) return

    const appendNew = shouldAppendNew({
      key,
      type,
      model: model as Model<TType>,
    })
    const isNew = targetStore[id] == null

    if (isNew && !appendNew) return

    if (ARCHIVED_TIME_KEY in model && model[ARCHIVED_TIME_KEY] != null) {
      handleArchivedTask(targetStore, target, id)
      return
    }

    handleModelUpsert(
      isNew,
      targetStore,
      id,
      model,
      type,
      key,
      target,
      cacheUpdates,
      appendNew
    )
  })

  return cacheUpdates as QueryCacheUpsert<TType>[]
}

function handleArchivedTask(
  targetStore: ModelRecord<any>,
  target: QueryCacheMatches,
  id: ModelId
) {
  delete targetStore[id]
  if ('ids' in target) {
    target.ids = target.ids.filter((x) => x !== id)
  }
}

function handleModelUpsert<TType extends keyof AllModelsSchema>(
  isNew: boolean,
  targetStore: ModelRecord<TType>,
  id: ModelId,
  model: Partial<Model<TType>>,
  type: TType,
  key: QueryKey,
  target: QueryCacheMatches,
  cacheUpdates: QueryCacheUpsert<TType>[],
  appendNew: boolean
) {
  if (isNew) {
    // Create new model
    const { applied } = appendModelToTargetStore(
      key,
      targetStore,
      id,
      model as Model<TType>
    )
    if (!applied.changed) return

    cacheUpdates.push({
      key,
      data: target,
      id,
      type: type as TType,
      updates: model,
      inverse: applied.inverse,
    })
  } else {
    // Update existing model
    const applied = applyPartialToTargetStore(targetStore, id, model)
    if (!applied.changed) return

    cacheUpdates.push({
      key,
      data: target,
      id,
      type: type as TType,
      updates: model,
      inverse: applied.inverse,
    })
  }

  if (appendNew) {
    const targetModelType = getResponseModelType(target)

    // If the model matches the target then update the ids
    if ('ids' in target && targetModelType === type && model.id != null) {
      target.ids = Array.from(new Set([...target.ids, model.id]))
    }
  }
}

function appendModelToTargetStore<TType extends keyof AllModelsSchema>(
  key: QueryKey,
  targetStore: ModelRecord<TType>,
  id: ModelId,
  model: Model<TType>
): { applied: ApplyModelReturn<TType> } {
  const now = Date.now()

  if (isModelCacheKey(key)) {
    targetStore[id] = { updatedAt: now, value: model }
  } else {
    targetStore[id] = model
  }

  return { applied: { changed: true, inverse: null, data: model } }
}
