import {
  type CustomFieldFilters,
  type EntityFilterState,
  type ProjectFilter,
  type TaskFilter,
  type WorkspaceFilter,
} from './types'

/** Keep in sync with packages/motion-extension/src/areas/project-management/pages/pm-v3/routes/types.ts */
type PageType =
  | 'all-tasks'
  | 'all-projects'
  | 'my-tasks'
  | 'team-schedule'
  | 'workspace'
  | 'folder'
  | 'project'

/* c8 ignore next */
export function getDefaultFilterState(type: PageType): EntityFilterState {
  if (type === 'project') return DEFAULT_PROJECT_FILTER_STATE
  return DEFAULT_FILTER_STATE
}

export const DEFAULT_CUSTOM_FIELD_FILTERS: CustomFieldFilters = {
  text: {},
  multiSelect: {},
  select: {},
  number: {},
  date: {},
  url: {},
  person: {},
  multiPerson: {},
}

export const DEFAULT_TASK_FILTERS: TaskFilter = {
  statusIds: null,
  stageDefinitionIds: null,
  assigneeUserIds: null,
  priorities: null,
  labelIds: null,
  createdByUserIds: null,
  estimatedCompletionTime: null,
  folderIds: null,

  recurring: null,
  autoScheduled: null,
  isBlocked: null,
  isBlocking: null,

  startDate: null,
  scheduledDate: null,
  dueDate: null,
  createdTime: null,
  updatedTime: null,
  completedTime: null,
  lastInteractedTime: null,
  type: null,

  completed: 'exclude',

  scheduledStatus: null,
  // Backend includes by default
  isUnvisitedStage: null,

  ...DEFAULT_CUSTOM_FIELD_FILTERS,
}

export const DEFAULT_PROJECT_FILTERS: ProjectFilter = {
  ids: null,
  statusIds: null,
  stageDefinitionIds: null,
  projectDefinitionIds: null,
  managerIds: null,
  priorities: null,
  labelIds: null,
  createdByUserIds: null,
  folderIds: null,

  dueDate: null,
  createdTime: null,
  updatedTime: null,
  startDate: null,

  completed: 'exclude',

  ...DEFAULT_CUSTOM_FIELD_FILTERS,
}
export const DEFAULT_WORKSPACE_FILTERS: WorkspaceFilter = {
  ids: null,
}

export const DEFAULT_FILTER_STATE: EntityFilterState = {
  $version: 7,
  target: 'tasks',
  tasks: {
    filters: DEFAULT_TASK_FILTERS,
    ordered: [],
  },
  projects: {
    filters: DEFAULT_PROJECT_FILTERS,
    ordered: [],
  },
  workspaces: {
    filters: DEFAULT_WORKSPACE_FILTERS,
    ordered: [],
  },
}

const DEFAULT_PROJECT_FILTER_STATE: EntityFilterState = {
  $version: 7,
  target: 'tasks',
  tasks: {
    filters: { ...DEFAULT_TASK_FILTERS, completed: 'include' },
    ordered: [],
  },
  projects: {
    filters: DEFAULT_PROJECT_FILTERS,
    ordered: [],
  },
  workspaces: {
    filters: DEFAULT_WORKSPACE_FILTERS,
    ordered: [],
  },
}
