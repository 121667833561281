import { createNoneId } from '@motion/shared/identifiers'

import * as v from 'valibot'

export const LooseTextFilterSchema = v.object({
  inverse: v.optional(v.boolean()),
  operator: v.picklist([
    'beginsWith',
    'endsWith',
    'contains',
    'in',
    'defined',
    'equals',
    'empty',
  ]),
  value: v.optional(v.union([v.array(v.string()), v.string()])),
})
export const RecordTextFilterSchema = v.record(v.record(LooseTextFilterSchema))

export const LooseNumberFilterSchema = v.object({
  inverse: v.optional(v.boolean()),
  operator: v.picklist([
    'range',
    'gt',
    'gte',
    'lt',
    'lte',
    'equals',
    'defined',
    'empty',
  ]),
  value: v.optional(
    v.union([
      v.array(v.string()),
      v.string(),
      v.number(),
      v.optional(v.object({ min: v.number(), max: v.number() })),
    ])
  ),
})
export const RecordNumberFilterSchema = v.record(
  v.record(LooseNumberFilterSchema)
)

export const LooseIdFilterSchema = v.optional(
  v.nullable(
    v.object({
      inverse: v.optional(v.boolean()),
      operator: v.string(),
      value: v.array(v.string()),
    })
  ),
  null
)
export type LooseIdFilterSchema = v.Output<typeof LooseIdFilterSchema>

export const LooseNullableIdFilterSchema = v.optional(
  v.nullable(
    v.object({
      inverse: v.optional(v.boolean()),
      operator: v.string(),
      value: v.transform(v.array(v.union([v.string(), v.null_()])), (data) =>
        data.map((x) => (x === null ? createNoneId('user') : x))
      ),
    })
  ),
  null
)
export type LooseNullableIdFilterSchema = v.Output<
  typeof LooseNullableIdFilterSchema
>

export const RecordIdFilterSchema = v.record(v.record(LooseIdFilterSchema))

export const LooseDateFilterSchema = v.optional(
  v.nullable(
    v.object({
      inverse: v.optional(v.boolean()),
      operator: v.string(),
      value: v.optional(
        v.union([
          v.string(),
          v.object({
            from: v.optional(v.string()),
            to: v.optional(v.string()),
          }),
        ])
      ),
      name: v.optional(v.string()),
      duration: v.optional(v.string()),
    })
  ),
  null
)

export type LooseDateValueFilterSchema = v.Output<typeof LooseDateFilterSchema>

export const RecordDateFilterSchema = v.record(v.record(LooseDateFilterSchema))

export const LooseBooleanFilterSchema = v.optional(v.nullable(v.string()), null)
export type LooseBooleanFilterSchema = v.Output<typeof LooseBooleanFilterSchema>

export const InclusionFilterSchema = v.optional(
  v.nullable(v.picklist(['include', 'exclude', 'only'])),
  'exclude'
)
const LooseInclusionFilterSchema = v.optional(
  v.nullable(v.picklist(['include', 'exclude', 'only'])),
  null
)

const CustomFieldsSchema = v.object({
  text: RecordTextFilterSchema,
  url: RecordTextFilterSchema,

  multiSelect: RecordIdFilterSchema,
  select: RecordIdFilterSchema,
  person: RecordIdFilterSchema,
  multiPerson: RecordIdFilterSchema,

  number: RecordNumberFilterSchema,

  date: RecordDateFilterSchema,
})

export const RecurringFilterSchema = v.optional(
  v.nullable(v.picklist(['CURRENT', 'FUTURE', 'ALL'])),
  null
)

export const TypeFilterSchema = v.optional(
  v.nullable(v.array(v.picklist(['RECURRING_INSTANCE', 'NORMAL', 'CHUNK']))),
  null
)

const withOrder = <T extends v.IntersectSchema<any> | v.ObjectSchema<any>>(
  schema: T
) => v.object({ ordered: v.array(v.string()), filters: schema })

export const Schema = v.object({
  $version: v.literal(7),
  target: v.picklist(['tasks', 'projects']),
  tasks: withOrder(
    v.intersect([
      v.object({
        statusIds: LooseIdFilterSchema,
        stageDefinitionIds: LooseNullableIdFilterSchema,
        assigneeUserIds: LooseNullableIdFilterSchema,
        priorities: LooseIdFilterSchema,
        labelIds: LooseIdFilterSchema,
        createdByUserIds: LooseIdFilterSchema,
        folderIds: LooseIdFilterSchema,

        dueDate: LooseDateFilterSchema,
        createdTime: LooseDateFilterSchema,
        updatedTime: LooseDateFilterSchema,

        startDate: LooseDateFilterSchema,
        lastInteractedTime: LooseDateFilterSchema,
        completedTime: LooseDateFilterSchema,
        estimatedCompletionTime: LooseDateFilterSchema,
        recurring: RecurringFilterSchema,
        type: TypeFilterSchema,

        scheduledDate: LooseDateFilterSchema,

        autoScheduled: LooseBooleanFilterSchema,
        isBlocked: LooseBooleanFilterSchema,
        isBlocking: LooseBooleanFilterSchema,

        completed: InclusionFilterSchema,
        isUnvisitedStage: LooseInclusionFilterSchema,

        scheduledStatus: LooseIdFilterSchema,
      }),
      CustomFieldsSchema,
    ])
  ),

  projects: withOrder(
    v.intersect([
      v.object({
        ids: LooseIdFilterSchema,
        statusIds: LooseIdFilterSchema,
        stageDefinitionIds: LooseNullableIdFilterSchema,
        projectDefinitionIds: LooseNullableIdFilterSchema,
        managerIds: LooseIdFilterSchema,
        priorities: LooseIdFilterSchema,
        labelIds: LooseIdFilterSchema,
        createdByUserIds: LooseIdFilterSchema,
        folderIds: LooseIdFilterSchema,

        dueDate: LooseDateFilterSchema,
        createdTime: LooseDateFilterSchema,
        updatedTime: LooseDateFilterSchema,
        startDate: LooseDateFilterSchema,

        completed: InclusionFilterSchema,
      }),
      CustomFieldsSchema,
    ])
  ),
  workspaces: withOrder(
    v.object({
      ids: LooseIdFilterSchema,
    })
  ),
})
