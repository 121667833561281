import { classed } from '@motion/theme'

export const TreeNodeShell = classed('div', {
  base: `
    flex items-center 
    rounded overflow-hidden
    min-w-0 min-h-8
    px-1 py-1
    select-none text-sm
  `,
  variants: {
    disabled: {
      true: `opacity-40 cursor-not-allowed pointer-events-none`,
      false: `cursor-pointer hover:bg-dropdown-item-bg-hover`,
    },
  },
})
