import { omit } from '@motion/utils/core'

import { defineMutation } from '../../core'
import { type RouteTypes } from '../types'

type CreateProjectDefinitionContext =
  RouteTypes<'DefinitionsController_createProjectDefinition'>
export const create = defineMutation<
  CreateProjectDefinitionContext['request'],
  CreateProjectDefinitionContext['response']
>().using({
  method: 'POST',
  uri: (args) => `/v2/workspaces/${args.workspaceId}/templates/projects`,
  body: (args) => omit(args, 'workspaceId'),
})

type UpdateProjectDefinitionContext =
  RouteTypes<'DefinitionsController_updateProjectDefinition'>
export const update = defineMutation<
  UpdateProjectDefinitionContext['request'],
  UpdateProjectDefinitionContext['response']
>().using({
  method: 'PUT',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/${args.id}`,
  body: (args) => omit(args, ['workspaceId', 'id']),
})

type DeleteProjectDefinitionContext =
  RouteTypes<'DefinitionsController_deleteProjectDefinition'>
export const deleteProjectDefinition = defineMutation<
  DeleteProjectDefinitionContext['request'],
  void
>().using({
  method: 'DELETE',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/${args.id}`,
})

type CopyProjectDefinitionContext =
  RouteTypes<'DefinitionsController_copyProjectDefinition'>
export const copyProjectDefinition = defineMutation<
  CopyProjectDefinitionContext['request'],
  CopyProjectDefinitionContext['response']
>().using({
  method: 'POST',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/copy/${args.id}`,
})
