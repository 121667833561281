import libMergeWith from 'lodash/mergeWith'
import { DateTime } from 'luxon'

type MergeFunction = {
  <T1, T2>(obj: T1, source: T2): T1 & T2
  <T1, T2, T3>(obj: T1, source1: T2, source2: T3): T1 & T2 & T3
  <T1, T2, T3, T4>(
    obj: T1,
    source1: T2,
    source2: T3,
    source3: T4
  ): T1 & T2 & T3 & T4
  <T1, T2, T3, T4, T5>(
    obj: T1,
    source1: T2,
    source2: T3,
    source3: T4,
    source4: T5
  ): T1 & T2 & T3 & T4 & T5
  (target: any, ...objects: any[]): any
}

export const merge: MergeFunction = (target: any, ...objects: any[]): any => {
  if (objects.length === 0) return target

  for (const obj of objects) {
    if (obj != null) {
      libMergeWith(target, obj, mergeSpecialType)
    }
  }

  return target
}

export const toMerged: MergeFunction = (...objects: any[]): any => {
  return merge({}, ...objects)
}

/**
 * Utils
 */

function mergeSpecialType(objValue: any, srcValue: any): any {
  // Handle arrays
  if (Array.isArray(objValue) || Array.isArray(srcValue)) {
    return srcValue
  }

  // Handle Maps
  if (objValue instanceof Map && srcValue instanceof Map) {
    return mergeMap(objValue, srcValue)
  }

  // Handle Sets
  if (objValue instanceof Set && srcValue instanceof Set) {
    return new Set([...objValue, ...srcValue])
  }

  // Handle Dates
  if (objValue instanceof Date && srcValue instanceof Date) {
    return new Date(srcValue)
  }

  // Handle DateTime (luxon)
  if (DateTime.isDateTime(objValue) && DateTime.isDateTime(srcValue)) {
    return srcValue
  }

  // Handle RegExp
  if (objValue instanceof RegExp && srcValue instanceof RegExp) {
    return new RegExp(srcValue)
  }

  // Return undefined to let lodash handle normal objects
  return undefined
}

function mergeMapValue(left: any, right: any): any {
  const specialMerged = mergeSpecialType(left, right)
  if (specialMerged !== undefined) {
    return specialMerged
  }

  // Handle objects (but not special types)
  if (left && right && typeof left === 'object' && typeof right === 'object') {
    return merge({}, left, right)
  }

  // Default: use source value
  return right
}

function mergeMap(target: Map<any, any>, source: Map<any, any>): Map<any, any> {
  const result = new (target.constructor as typeof Map)()

  // Copy all entries from target
  target.forEach((value, key) => {
    result.set(key, value)
  })

  // Merge entries from source
  source.forEach((srcValue, key) => {
    const targetValue = result.get(key)
    result.set(key, mergeMapValue(targetValue, srcValue))
  })

  return result
}
