import { API } from '@motion/rpc'
import type {
  GetTasksV2FilterWithOperatorsSchema,
  TasksV2QuerySchema,
} from '@motion/rpc-types'
import { createTaskFilterFn } from '@motion/ui-logic/pm/data'

import type { QueryKey } from '@tanstack/react-query'

import { isModelCacheKey } from '../../model-cache'
import { partialMatchKey } from '../../utils'
import { type AppendNewType, type ModelWithType } from '../types'

export function createAppendNewTaskFilterFn() {
  return (queryKey: QueryKey): AppendNewType<'tasks'> => {
    // Append all new tasks to the model cache and by id cache
    if (
      isModelCacheKey(queryKey) ||
      partialMatchKey(queryKey, API.tasksV2.queryKeys.byIdRoot) ||
      partialMatchKey(queryKey, API.scheduledEntities.queryKeys.root)
    ) {
      return true
    }

    if (!partialMatchKey(queryKey, API.tasksV2.queryKeys.query)) return false

    const filterArg = queryKey[queryKey.length - 1]
    if (!isTaskQuery(filterArg)) return false
    if (filterArg.filters.length === 0) return false

    const filterTask = createTaskFilterFn(
      filterArg.filters as GetTasksV2FilterWithOperatorsSchema[]
    )

    return (item) => {
      return filterTask(item.model)
    }
  }
}

export function isTaskType(t: ModelWithType<any>): t is ModelWithType<'tasks'> {
  return t.type === 'tasks'
}

export function isTaskQuery(value: unknown): value is TasksV2QuerySchema {
  if (value == null) return false
  if (typeof value !== 'object') return false
  return (
    '$version' in value &&
    'filters' in value &&
    Array.isArray(value.filters) &&
    value.filters.length > 0
  )
}
