import { type CalendarEventSchemaV2 } from '@motion/rpc-types'

export function canAddToProject(
  event: CalendarEventSchemaV2 | null | undefined
) {
  if (event == null) return false

  return (
    event.meetingTaskId == null &&
    event.recurringEventId == null &&
    !event.isAllDay
  )
}

export function canRemoveFromProject(
  event: CalendarEventSchemaV2 | null | undefined
) {
  if (event == null) return false

  return event.meetingTaskId != null
}
