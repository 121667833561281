import React, { type ReactNode } from 'react'

import { ExpandableTreeNode, type Node } from './tree'
import { NodeSearch } from './tree/node-search'
import { RecursiveTreeNode } from './tree/recursive-tree-node'
import { TreeNodeContentWithBreadcrumbs } from './tree/tree-node-content-with-path'

import {
  GrowOnlyDiv,
  SearchableListContent,
  SearchableListInput,
  SelectableListItem,
} from '../components'
import { useItemSelectors } from '../hooks'
import { MAX_HEIGHT } from '../utils'

export type SearchableTreeProps = {
  inputProps?: { placeholder: string }
  rootNode: Node
  onSelect: (node: Node) => void
  computeSelected: (node: Node) => boolean
  renderLabel: (node: Node) => ReactNode
}

export const SearchableTree = (props: SearchableTreeProps) => {
  const {
    inputProps = { placeholder: 'Filter...' },
    rootNode,
    onSelect,
    computeSelected,
    renderLabel,
  } = props

  const [search, setSearch] = React.useState<string>('')
  const [activeValue, setActiveValue] = React.useState<string | null>(null)
  const isDirty = React.useRef<boolean>(false)

  const containerRef = React.useRef<HTMLDivElement>(null)

  const { getFirstActiveItemValue } = useItemSelectors(containerRef)

  const handleInputValueChange = React.useCallback((search: string) => {
    setSearch(search)
  }, [])

  React.useLayoutEffect(() => {
    // selected the first item whenever the filtered items change, but only when the input is dirty
    if (!isDirty.current && !search) {
      return
    }
    isDirty.current = true
    const newValue = getFirstActiveItemValue()
    setActiveValue(newValue || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <SearchableListContent
      search={search}
      activeValue={activeValue}
      setActiveValue={setActiveValue}
    >
      <SearchableListInput
        {...inputProps}
        onValueChange={handleInputValueChange}
        search={search}
      />
      <GrowOnlyDiv
        ref={containerRef}
        calculateWhenChange={search}
        style={{
          maxHeight: MAX_HEIGHT,
          transitionProperty: 'height',
          transitionDuration: '75ms',
        }}
        className='scrollbar-none scroll-py-1 max-w-sm overflow-y-auto overflow-x-hidden p-1 w-80'
      >
        {search.length ? (
          <NodeSearch
            rootNode={rootNode}
            search={search}
            renderNode={(node) => {
              return (
                // Note: this is only required while we don't have virtualization. The render prop will be removed please don't copy.
                <SelectableListItem
                  onSelect={() => {
                    onSelect(node)
                  }}
                  value={node.id}
                  active={activeValue === node.id}
                  setActiveValue={setActiveValue}
                  selected={computeSelected(node)}
                  height={48}
                >
                  <div className='min-w-0'>
                    <TreeNodeContentWithBreadcrumbs
                      prettyPath={node.prettyPath}
                    >
                      {renderLabel(node)}
                    </TreeNodeContentWithBreadcrumbs>
                  </div>
                </SelectableListItem>
              )
            }}
          />
        ) : (
          <RecursiveTreeNode
            node={rootNode}
            renderNode={(node, { toggleExpanded, isExpanded }) => {
              if (!node.id) return null
              return (
                <ExpandableTreeNode
                  selected={computeSelected(node)}
                  onSelect={() => {
                    onSelect(node)
                  }}
                  isExpanded={isExpanded}
                  expandable={node.nodes != null}
                  onExpandClick={toggleExpanded}
                  disabled={node.disabled}
                >
                  {renderLabel(node)}
                </ExpandableTreeNode>
              )
            }}
          />
        )}
      </GrowOnlyDiv>
    </SearchableListContent>
  )
}
