import { XSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'

export type TagProps = {
  children: React.ReactNode

  color?: VariantProps<typeof Container>['color']
  variant?: VariantProps<typeof Container>['variant']
  size?: VariantProps<typeof Container>['size']

  onRemove?: React.MouseEventHandler<HTMLButtonElement>
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const Tag = ({
  children,
  color = 'grey',
  variant = 'default',
  size = 'normal',
  onRemove,
  onClick,
}: TagProps) => {
  return (
    <Container {...{ color, variant, size }}>
      <ChildContainer onClick={onClick}>{children}</ChildContainer>
      {onRemove && (
        <button type='button' onClick={onRemove}>
          <XIcon size={size} />
        </button>
      )}
    </Container>
  )
}

const ChildContainer = ({
  children,
  onClick,
}: Pick<TagProps, 'children' | 'onClick'>) =>
  onClick ? (
    <button className='truncate' onClick={onClick}>
      {children}
    </button>
  ) : (
    <div className='truncate'>{children}</div>
  )

const Container = classed('div', {
  base: `
    flex flex-row
    items-center
    gap-1
    rounded-full
    truncate
    select-none
    cursor-auto
  `,
  variants: {
    size: {
      normal: 'text-sm py-0.5 px-2 h-5',
      small: 'text-xs py-0 px-1.5 h-4',
    },
    color: {
      yellow: '',
      green: '',
      red: '',
      blue: '',
      grey: '',
    },
    variant: {
      default: '',
      subtle: '',
    },
  },
  compoundVariants: [
    {
      color: 'yellow',
      variant: 'default',
      className: 'bg-tag-yellow-strong-bg text-tag-yellow-strong-text',
    },
    {
      color: 'yellow',
      variant: 'subtle',
      className: 'bg-tag-yellow-subtle-bg text-tag-yellow-subtle-text',
    },
    {
      color: 'green',
      variant: 'default',
      className: 'bg-tag-green-strong-bg text-tag-green-strong-text',
    },
    {
      color: 'green',
      variant: 'subtle',
      className: 'bg-tag-green-subtle-bg text-tag-green-subtle-text',
    },
    {
      color: 'red',
      variant: 'default',
      className: 'bg-tag-red-strong-bg text-tag-red-strong-text',
    },
    {
      color: 'red',
      variant: 'subtle',
      className: 'bg-tag-red-subtle-bg text-tag-red-subtle-text',
    },
    {
      color: 'blue',
      variant: 'default',
      className: 'bg-tag-brand-strong-bg text-tag-brand-strong-text',
    },
    {
      color: 'blue',
      variant: 'subtle',
      className: 'bg-tag-brand-subtle-bg text-tag-brand-subtle-text',
    },
    {
      color: 'grey',
      variant: 'default',
      className: 'bg-tag-default-strong-bg text-tag-default-strong-text',
    },
    {
      color: 'grey',
      variant: 'subtle',
      className: 'bg-tag-default-subtle-bg text-tag-default-subtle-text',
    },
  ],
})

const XIcon = classed(XSolid, {
  base: 'cursor-pointer hover:brightness-95 active:brightness-100 flex-shrink-0',
  variants: {
    size: {
      normal: 'w-3 h-3',
      small: 'w-2.5 h-2.5',
    },
  },
})
