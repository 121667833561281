import { type PropsWithChildren } from 'react'

type TreeNodeContentWithBreadcrumbsProps = PropsWithChildren<{
  prettyPath?: string[]
}>

export const TreeNodeContentWithBreadcrumbs = (
  props: TreeNodeContentWithBreadcrumbsProps
) => {
  const { prettyPath = [], children } = props
  return (
    <>
      <div className='flex items-center'>{children}</div>

      {prettyPath.length > 0 && (
        <div className='text-semantic-neutral-text-disabled text-xs px-1 mt-0.5'>
          {prettyPath.join(' / ')}
        </div>
      )}
    </>
  )
}
