import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { FilePreviewFallback } from './file-preview-fallback'

import { LoadingSpinner } from '../../../spinners'
import { type FilePreviewModalProps } from '../file-preview-modal'

export type FilePreviewImageProps = Pick<
  FilePreviewModalProps,
  'fileUrl' | 'fileName' | 'onDownload'
>

export function FilePreviewImage({
  fileUrl,
  fileName,
  onDownload,
}: FilePreviewImageProps) {
  const [imageError, setImageError] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    setImageLoaded(false)
  }, [fileUrl])

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  const handleImageError = () => {
    setImageError(true)
  }

  if (imageError)
    return <FilePreviewFallback fileName={fileName} onDownload={onDownload} />

  return (
    <div className='flex items-center justify-center h-full'>
      <LoadingSpinner
        size={40}
        className={twMerge('flex', imageLoaded && 'hidden')}
      />
      <img
        src={fileUrl}
        alt={fileName}
        className={twMerge(
          'object-scale-down h-full w-full py-8 px-16 hidden',
          imageLoaded && 'flex opacity-100'
        )}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </div>
  )
}
