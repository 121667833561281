import { classed, type VariantProps } from '@motion/theme'

import { type ComponentRef, forwardRef, type MouseEventHandler } from 'react'

import { InternalCheckbox, type InternalCheckboxProps } from '../internal'

export type CheckboxProps = Omit<InternalCheckboxProps, 'className'> &
  VariantProps<typeof Wrapper>

const preventBubble: MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
  e.stopPropagation()
}

export const Checkbox = forwardRef<
  ComponentRef<typeof InternalCheckbox>,
  CheckboxProps
>(function Checkbox({ variant, ...rest }, ref) {
  return (
    <Wrapper variant={variant} onClick={preventBubble}>
      <InternalCheckbox ref={ref} {...rest} />
    </Wrapper>
  )
})

const Wrapper = classed('div', {
  base: `
    contents

    themevar-[c-border=form-border-default]
    themevar-[c-border-hover=form-border-hover]
    themevar-[c-bg=form-bg-default]
    themevar-[c-border-checked=form-bg-active-default]
  `,
  variants: {
    variant: {
      solid: `
      themevar-[c-icon=form-icon-active]
      themevar-[c-bg-checked=form-bg-active-default]
      themevar-[c-bg-checked-hover=form-bg-active-hover]
      themevar-[c-border-checked-hover=form-bg-active-hover]
      `,
      outlined: `
      themevar-[c-icon=form-bg-active-default]
      themevar-[c-border-checked=form-bg-active-default]
      themevar-[c-bg-checked=form-bg-default]
      themevar-[c-bg-checked-hover=form-bg-default-hover]
      themevar-[c-border-checked-hover=form-bg-active-hover]
      themevar-[c-icon-checked-hover=form-bg-active-hover]
      `,
    },
  },
  defaultVariants: {
    variant: 'solid',
  },
})
