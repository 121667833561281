import { XSolid, ZoomInOutline } from '@motion/icons'
import { useOnValueChange } from '@motion/react-core/hooks'
import { classed } from '@motion/theme'
import { clamp } from '@motion/utils/math'

import { type PointerEvent, type SyntheticEvent, useRef, useState } from 'react'

import { UnstyledModal, type UnstyledModalProps } from './unstyled-modal'

import { IconButton } from '../button'

export type ImageViewerModalProps = Pick<
  UnstyledModalProps,
  'visible' | 'onClose'
> & {
  src: string
  alt: string
  zoomAmount?: number
}

export function ImageViewerModal({
  src,
  alt,
  zoomAmount = 2,
  ...rest
}: ImageViewerModalProps) {
  const [isZoomed, setIsZoomed] = useState(false)
  const [isVertical, setIsVertical] = useState(false)

  const zoomAreaRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const pointerDownPosition = useRef({ x: 0, y: 0 })

  const onPointerDown = (e: PointerEvent<HTMLDivElement>) => {
    setIsZoomed(!isZoomed)

    if (!isZoomed) {
      pointerDownPosition.current = { x: e.clientX, y: e.clientY }
    }
  }

  const onPointerMove = (e: PointerEvent<HTMLDivElement>) => {
    onUpdateZoomImgTransform(e.clientX, e.clientY)
  }

  const onUpdateZoomImgTransform = (x: number, y: number) => {
    requestAnimationFrame(() => {
      if (!isZoomed || imageRef.current == null || zoomAreaRef.current == null)
        return

      const containerRect = zoomAreaRef.current.getBoundingClientRect()

      const xCenter = containerRect.x + containerRect.width / 2
      const yCenter = containerRect.y + containerRect.height / 2

      const xTranslate = (xCenter - x) * zoomAmount
      const yTranslate = (yCenter - y) * zoomAmount

      const imageWidth = imageRef.current.width
      const imageHeight = imageRef.current.height

      const xBound = Math.max(
        (imageWidth * zoomAmount - containerRect.width) / 2,
        0
      )
      const yBound = Math.max(
        (imageHeight * zoomAmount - containerRect.height) / 2,
        0
      )

      imageRef.current.style.transform = constructTransform(
        zoomAmount,
        clamp(xTranslate, -xBound, xBound),
        clamp(yTranslate, -yBound, yBound)
      )
    })
  }

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const { naturalHeight, naturalWidth } = e.currentTarget
    setIsVertical(naturalHeight > naturalWidth)
  }

  useOnValueChange(isZoomed, () => {
    if (isZoomed) {
      onUpdateZoomImgTransform(
        pointerDownPosition.current.x,
        pointerDownPosition.current.y
      )
    } else if (imageRef.current) {
      imageRef.current.style.transform = constructTransform(1, 0, 0)
    }
  })

  useOnValueChange(rest.visible, (visible) => {
    if (!visible) {
      setIsZoomed(false)
      pointerDownPosition.current = { x: 0, y: 0 }
    }
  })

  return (
    <UnstyledModal {...rest} withAnimation overlayClassName='bg-modal-overlay'>
      <ZoomArea
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        ref={zoomAreaRef}
        isZoomed={isZoomed}
        vertical={isVertical}
      >
        <Image
          src={src}
          alt={alt}
          ref={imageRef}
          onLoad={onImageLoad}
          style={{
            transform: constructTransform(1, 0, 0),
            transformOrigin: 'center',
            zIndex: 1,
            backgroundColor: isZoomed ? 'white' : undefined,
            cursor: isZoomed ? 'zoom-out' : 'zoom-in',
          }}
        />
      </ZoomArea>
      <ClickToZoom visible={!isZoomed}>
        <ZoomInOutline className='size-4' /> Click to zoom
      </ClickToZoom>
      <div className='absolute top-0 -right-7'>
        <IconButton
          icon={XSolid}
          size='small'
          sentiment='onDark'
          variant='muted'
          aria-label='Close'
          onClick={rest.onClose}
        />
      </div>
    </UnstyledModal>
  )
}

const ClickToZoom = classed('span', {
  base: `
    absolute mt-4 bottom-2 left-1/2 -translate-x-1/2
    bg-semantic-neutral-bg-active-default rounded-full px-2 py-1 shadow-lg opacity-80
    flex items-center gap-1
    transition-opacity duration-200
  `,
  variants: {
    visible: {
      true: 'opacity-100 z-10',
      false: 'opacity-0',
    },
  },
})

function constructTransform(
  scale: number,
  translateX: number,
  translateY: number
) {
  return `translate(${translateX}px, ${translateY}px) scale(${scale})`
}

const ZoomArea = classed('div', {
  base: `
    flex items-center justify-center
    relative
    overflow-hidden

    setvar-[container-padding=5px]
    sm:setvar-[container-padding=72px]
  `,
  variants: {
    vertical: {
      true: 'h-screen w-auto',
      false: 'h-auto w-screen',
    },
    isZoomed: {
      true: 'h-[calc(100vh-var(--container-padding))] w-[calc(100vw-var(--container-padding))]',
      false: `
        max-h-[calc(100vh-var(--container-padding))] 
        max-w-[min(1200px,calc(100vw-var(--container-padding)))]
        [&>img]:size-full
      `,
    },
  },
})

const Image = classed('img', {
  base: `
    object-contain
    cursor-zoom-in
  `,
})
