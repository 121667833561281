import { DateTime } from 'luxon'

import { isEnabledStage } from './stages'

import { type StageArg } from '../form-fields'

export type AdjustStageDueDatesProps = {
  stageDueDates: StageArg[]
  projectStart: string | null
  updatedStage?: StageArg
}

export type AdjustStageDueDatesReturn = {
  adjustedStagesDueDates: StageArg[]
  newProjectStart: string | null
  newProjectDeadline: string
}

/**
 * Adjusts the due dates of the stages (and possibly project start date and due date) in a project.
 *
 * If updatedStage is provided, update prior/next stages due dates according to the method selected
 * and whether the stage is moving forward or backward in time.
 *
 * Default behavior:
 * If moving back in time, use "accordion" method.
 * If moving forward in time, use "expand" method.
 *
 * See spec for reference:
 * https://www.notion.so/motionapp/Flow-Project-Date-Change-Spec-1041471137eb80dab9f0e7b3d24b0e09
 */
export const getAdjustStageDueDatesFn = ({
  accordionOnly = false,
}: { accordionOnly?: boolean } = {}) => {
  return (params: AdjustStageDueDatesProps): AdjustStageDueDatesReturn => {
    const { stageDueDates, projectStart, updatedStage } = params
    if (!Array.isArray(stageDueDates) || stageDueDates.length === 0) {
      throw new Error('Invalid stageDueDates: must be a non-empty array', {
        cause: params,
      })
    }
    if (projectStart) {
      try {
        const date = DateTime.fromISO(projectStart)
        if (
          date.hour !== 0 ||
          date.minute !== 0 ||
          date.second !== 0 ||
          date.millisecond !== 0
        ) {
          throw new Error()
        }
      } catch (err) {
        throw new Error(
          'Invalid projectStart: must be a valid date-only string',
          { cause: params }
        )
      }
    }

    let newProjectStart = projectStart
    let adjustedStagesDueDates = [...stageDueDates]

    // Handle project start date changes
    if (projectStart) {
      const projectStartDateTime = DateTime.fromISO(projectStart)
      adjustedStagesDueDates = adjustedStagesDueDates.map((stage) => {
        const stageDateTime = DateTime.fromISO(stage.dueDate)
        return stageDateTime < projectStartDateTime
          ? { ...stage, dueDate: projectStart }
          : stage
      })
    }

    // Handle individual stage changes if updatedStage is provided
    if (updatedStage) {
      const updatedStageIndex = adjustedStagesDueDates.findIndex(
        (stage) => stage.stageDefinitionId === updatedStage.stageDefinitionId
      )

      if (updatedStageIndex === -1) {
        throw new Error('Updated stage not found in stages', {
          cause: params,
        })
      }

      const newDateTime = DateTime.fromISO(updatedStage.dueDate)
      const originalDateTime = DateTime.fromISO(
        adjustedStagesDueDates[updatedStageIndex].dueDate
      )
      const isMovingBackward = newDateTime < originalDateTime

      adjustedStagesDueDates = adjustedStagesDueDates.map(
        (stage, stageIndex) => {
          if (stageIndex === updatedStageIndex) {
            return { ...stage, dueDate: newDateTime.toISODate() }
          }

          if (
            accordionOnly ||
            (isMovingBackward && stageIndex < updatedStageIndex)
          ) {
            return accordionMethod(
              stage,
              stageIndex,
              updatedStageIndex,
              newDateTime
            )
          } else if (!isMovingBackward && stageIndex > updatedStageIndex) {
            return expandMethod(stage, newDateTime, originalDateTime)
          }

          return stage
        }
      )
    }

    const activeStages = adjustedStagesDueDates.filter(isEnabledStage)
    if (activeStages.length === 0) {
      throw new Error('No active stages found', {
        cause: params,
      })
    }

    // Ensure project start is not after the first stage
    if (
      projectStart &&
      DateTime.fromISO(activeStages[0].dueDate) < DateTime.fromISO(projectStart)
    ) {
      newProjectStart = activeStages[0].dueDate
    }

    // Keep project deadline in sync with the last active stage
    const newProjectDeadline = activeStages[activeStages.length - 1].dueDate

    return {
      adjustedStagesDueDates,
      newProjectStart,
      newProjectDeadline,
    }
  }
}

function expandMethod(
  stage: StageArg,
  newDateTime: DateTime,
  originalDateTime: DateTime
) {
  const stageDueDateDateTime = DateTime.fromISO(stage.dueDate)

  const diff = newDateTime.diff(originalDateTime)
  const newStageDate = stageDueDateDateTime.plus(diff)

  return { ...stage, dueDate: newStageDate.toISODate() }
}

function accordionMethod(
  stage: StageArg,
  stageIndex: number,
  index: number,
  newDateTime: DateTime
) {
  const stageDueDateDateTime = DateTime.fromISO(stage.dueDate)

  if (
    (stageIndex <= index && stageDueDateDateTime > newDateTime) ||
    (stageIndex >= index && stageDueDateDateTime < newDateTime)
  ) {
    return { ...stage, dueDate: newDateTime.toISODate() }
  }

  return stage
}
