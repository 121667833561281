import {
  definedValueCount,
  sortAndDedupArrayValues,
  stripUndefined,
} from '@motion/utils/object'
import { type RequiredKeys } from '@motion/utils/types'
import { type GetTasksV2FilterWithOperatorsSchema } from '@motion/zod/client'

import { getCustomFieldFilters } from './custom-fields'
import { buildDateFilterQuery } from './date'
import {
  emptyInToUndefined,
  formatInclusionFilterToBooleanFilter,
  formatNullableUserFilter,
} from './helpers'
import { buildProjectFilterFrom } from './projects'
import { type DataFilters } from './types'
import { getCompletedFilter } from './utils'

import { createNoneProject } from '../../none-entities'
import { type AppDataContext } from '../../types'

export function buildTaskFilter(
  ctx: AppDataContext,
  filters: DataFilters
): GetTasksV2FilterWithOperatorsSchema[] | null {
  const workspaceIds =
    filters.workspaces.ids == null || filters.workspaces.ids.value.length === 0
      ? ctx.workspaces.all().map((x) => x.id)
      : filters.workspaces.ids.value

  if (
    definedValueCount(filters.tasks) === 0 &&
    definedValueCount(filters.projects) === 0
  ) {
    return [
      sortAndDedupArrayValues({
        workspaceIds,
      }),
    ]
  }

  const queryFilter = getTaskQueryFilter(ctx, filters, workspaceIds)
  if (queryFilter == null) return null

  return [sortAndDedupArrayValues(stripUndefined(queryFilter))]
}

type ExactTaskQueryFilter = RequiredKeys<
  Omit<
    GetTasksV2FilterWithOperatorsSchema,
    | 'archived'
    | 'endDate'
    | 'ids'
    | 'isBlocked'
    | 'isBlocking'
    | 'scheduledEnd'
    | 'scheduledStart'
    | 'startDate'
    | 'projectIds'
  >
> &
  Pick<GetTasksV2FilterWithOperatorsSchema, 'projectIds'>

function getTaskQueryFilter(
  ctx: AppDataContext,
  filters: DataFilters,
  workspaceIds: string[]
): ExactTaskQueryFilter | null {
  const taskFilters = filters.tasks
  const allProjects = [
    ...ctx.projects.all(),
    ...ctx.workspaces.all().map((w) => createNoneProject(w.id)),
  ]
  const selectProjects = buildProjectFilterFrom(ctx, filters, {
    completed: 'include',
  })
  const selectedProjects = allProjects.filter(selectProjects)

  if (selectedProjects.length === 0) return null

  const effectiveCompletedFilter = getCompletedFilter(filters.tasks)

  let selectedProjectIds: string[] | [null] = selectedProjects.map((x) => x.id)

  if (filters.projects.ids != null && filters.projects.ids.value.length === 0) {
    selectedProjectIds = [null]
  }

  return {
    ...(selectedProjectIds.length !== allProjects.length && {
      projectIds: { operator: 'in', value: selectedProjectIds },
    }),
    workspaceIds,
    statusIds: emptyInToUndefined(taskFilters.statusIds),
    labelIds: emptyInToUndefined(taskFilters.labelIds),
    priorities: emptyInToUndefined(taskFilters.priorities),
    assigneeUserIds: formatNullableUserFilter(taskFilters.assigneeUserIds),
    createdByUserIds: emptyInToUndefined(taskFilters.createdByUserIds),
    createdTime: buildDateFilterQuery(taskFilters.createdTime ?? undefined),
    updatedTime: buildDateFilterQuery(taskFilters.updatedTime ?? undefined),
    completedTime: buildDateFilterQuery(taskFilters.completedTime ?? undefined),
    estimatedCompletionTime: buildDateFilterQuery(
      taskFilters.estimatedCompletionTime ?? undefined
    ),
    lastInteractedTime: buildDateFilterQuery(
      taskFilters.lastInteractedTime ?? undefined
    ),
    dueDate: buildDateFilterQuery(taskFilters.dueDate ?? undefined),
    customFields: getCustomFieldFilters(taskFilters),
    completed: effectiveCompletedFilter,
    stageDefinitionIds: emptyInToUndefined(taskFilters.stageDefinitionIds),
    isAutoScheduled: formatInclusionFilterToBooleanFilter(
      taskFilters.autoScheduled
    ),
    scheduledStatus: emptyInToUndefined(taskFilters.scheduledStatus),
    recurring:
      !taskFilters.type || taskFilters.type.includes('RECURRING_INSTANCE')
        ? 'CURRENT'
        : undefined,
    type: taskFilters.type ?? ['NORMAL', 'RECURRING_INSTANCE'],
    isUnvisitedStage: taskFilters.isUnvisitedStage ?? undefined,
    folderIds: emptyInToUndefined(taskFilters.folderIds),
  }
}
