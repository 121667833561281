import { type Node, type NodeWithMetadata, type RootNode } from './types'

export function getLeafNodesWithPath(
  rootNode: Node,
  prettyPath: string[] = []
): NodeWithMetadata[] {
  const leafNodes: NodeWithMetadata[] = []

  for (const childNode of rootNode.nodes || []) {
    if (childNode.disabled) continue // Skip disabled nodes

    const currentPath = [...prettyPath, childNode.label].filter(Boolean)
    if (childNode.nodes) {
      leafNodes.push(...getLeafNodesWithPath(childNode, currentPath))
    } else {
      leafNodes.push({ ...childNode, prettyPath })
    }
  }

  return leafNodes
}

export function isRootNode(node: Node): node is RootNode {
  return node.id === '[ROOT]'
}
