import { ProjectCubeSolid, type SvgIconProps } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { ProjectPalette } from '../palette'

type ProjectColoredIconProps = SvgIconProps & {
  color?: COLOR
  noProject?: boolean
}
export const ProjectColoredIcon = forwardRef<
  SVGSVGElement,
  ProjectColoredIconProps
>(function ProjectIcon(
  { color = 'gray', noProject = false, className, ...etc },
  ref
) {
  return (
    <ProjectPalette color={color}>
      <ProjectCubeSolid
        ref={ref}
        {...etc}
        className={twMerge(
          '!text-palette-highlight-default shrink-0',
          noProject && 'opacity-40',
          className
        )}
      />
    </ProjectPalette>
  )
})
