import { API, type LooseApiDefinition } from '@motion/rpc'

export type QueryReference = {
  query: LooseApiDefinition
  args?: unknown
  staleTime?: number
}

const TEN_MINUTES = 10 * 60 * 1000

export const prefetchQueries = {
  duringAuth: [],
  postAuth: [
    {
      query: API.workspacesV2.getWorkspaces,
      args: {
        include: [
          'labels' as const,
          'projects' as const,
          'statuses' as const,
          'users' as const,
          'customFields' as const,
          'projectDefinitions' as const,
          'legacyProjectTemplates' as const,
          'folders' as const,
          'attachments' as const,
        ],
      },
      staleTime: TEN_MINUTES,
    },
    { query: API.views.getAll, staleTime: TEN_MINUTES },
    { query: API.usersV2.getMySettings, staleTime: TEN_MINUTES },
  ],
} satisfies { duringAuth: QueryReference[]; postAuth: QueryReference[] }
