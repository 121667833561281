import { FileTypeOther, type SvgIconProps } from '@motion/icons'

import { ICONS_MIME_TYPE_REGEX } from './constants'

export type FileIconProps = SvgIconProps & {
  mimeType: string
}

export function FileIcon({ mimeType, ...rest }: FileIconProps) {
  const [FileTypeIcon] =
    ICONS_MIME_TYPE_REGEX.find(([, regex]) => regex.test(mimeType)) || []

  if (FileTypeIcon) {
    return <FileTypeIcon {...rest} />
  }

  return <FileTypeOther {...rest} />
}
