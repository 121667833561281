import type { AllModelsSchema } from '@motion/rpc-types'
import { entries } from '@motion/utils/object'

import type { QueryClient, QueryFilters, QueryKey } from '@tanstack/react-query'

import { applyPartialToTargetStore } from './shared'
import {
  type ModelRecord,
  type QueryCacheMatches,
  type QueryCachePatch,
} from './types'
import { matchQueries, updateQueryData } from './utils'

import { type Model } from '../model-cache'
import { type PatchStoreShape } from '../types'
import { log } from '../utils'

/**
 * Patch the cache with a partial update.
 *
 * @param client - The QueryClient instance to perform the update on.
 * @param filter - The QueryFilters to determine which queries to update.
 * @param patch - The partial update to apply to the cache.
 *
 * @returns An array of QueryCachePatch objects representing the changes made.
 */
export function applyPartialToCaches<TType extends keyof AllModelsSchema>(
  client: QueryClient,
  filter: QueryFilters,
  typeOrTypes: TType | TType[],
  patch: PatchStoreShape
): QueryCachePatch<TType>[] {
  const queries = matchQueries(client, filter)

  const cacheUpdates: QueryCachePatch<TType>[] = []

  log.time('patch.all', () => {
    queries.forEach(([key, data]) => {
      if (data == null) return

      log.time('patch', (): void => {
        const updates = processQueryCachePatch(data, typeOrTypes, patch, key)
        cacheUpdates.push(...updates)
      })
    })
  })

  updateQueryData(client, cacheUpdates)

  return cacheUpdates
}

function processQueryCachePatch<TType extends keyof AllModelsSchema>(
  data: QueryCacheMatches,
  typeOrTypes: TType | TType[],
  patch: PatchStoreShape,
  key: QueryKey
): QueryCachePatch<TType>[] {
  const cacheUpdates: QueryCachePatch<TType>[] = []

  const types = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes]
  types.forEach((type) => {
    const targetStore = data.models[type] as ModelRecord<TType>
    if (targetStore == null || patch == null) return

    entries(patch).forEach(([_id, _model]) => {
      const id = _id as string // TODO: Why is id string | number without cast?
      const model = _model as Partial<Model<TType>>

      const applied = applyPartialToTargetStore(targetStore, id, model)

      if (!applied.changed) return

      log.debug('applied', {
        key,
        type,
        id,
        updates: model,
        target: targetStore[id],
      })

      cacheUpdates.push({
        key,
        data,
        id,
        type,
        updates: model,
        inverse: applied.inverse as Partial<Model<typeof type>>,
      })
    })
  })

  return cacheUpdates
}
