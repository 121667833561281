import { type AllModelsSchema } from '@motion/rpc-types'

import { type QueryClient } from '@tanstack/react-query'

import { INDEX_DEFINITIONS } from './definitions'
import { type CacheIndexes, type IndexName, type IndexTargets } from './types'

import { type QueryCacheUpsert } from '../methods'
import { getModelCache, type Model, type ModelId } from '../model-cache'

export function readIndex(
  client: QueryClient,
  indexName: IndexName,
  sourceId: ModelId
): ModelId[] {
  return getIndexedIds(client, indexName, sourceId)
}

export function hydrateFromIndex<T extends IndexTargets>(
  client: QueryClient,
  indexName: IndexName,
  sourceId: ModelId
): Model<T>[] {
  const indexTargetType = INDEX_DEFINITIONS.find(
    (def) => def.name === indexName
  )?.targetType
  if (!indexTargetType) {
    throw new Error(`Cannot find model type for index ${indexName}`, {
      cause: {
        indexName,
        sourceId,
      },
    })
  }

  return getIndexedIds(client, indexName, sourceId)
    .map((id) => {
      const cache = getModelCache(client)
      const model = cache[indexTargetType][id]
      return model?.value as Model<T> | undefined
    })
    .filter(Boolean)
}

export function getIndexedIds(
  client: QueryClient,
  indexName: IndexName,
  sourceId: ModelId
): ModelId[] {
  const indexes = getCacheIndexes(client)
  return indexes[indexName]?.[sourceId]?.relatedIds ?? []
}

export function updateIndexForModel<TType extends keyof AllModelsSchema>(
  client: QueryClient,
  type: TType,
  model: Partial<Model<TType>> & { id: ModelId },
  cacheUpdates?: QueryCacheUpsert<keyof AllModelsSchema>[]
) {
  const indexes = getCacheIndexes(client)

  INDEX_DEFINITIONS.filter((def) => def.targetType === type).forEach((def) => {
    const relationField = def.targetRelationField as keyof typeof model
    const relationId = model[relationField] as ModelId | undefined

    if (!relationId) return

    const update = cacheUpdates?.find(
      (update) =>
        update.type === type &&
        update.id === model.id &&
        relationField in update.updates
    )

    handleOldRelation(indexes, def, model, relationField, update)

    const entry = getOrCreateIndexEntry(indexes, def.name, relationId)
    updateRelatedIds(entry, model.id, 'add')
  })

  updateCacheIndexes(client, indexes)
}

export function removeIndexForModel<TType extends keyof AllModelsSchema>(
  client: QueryClient,
  type: TType,
  model: Partial<Model<TType>> & { id: ModelId }
) {
  const indexes = getCacheIndexes(client)

  INDEX_DEFINITIONS.filter((def) => def.targetType === type).forEach((def) => {
    const relationField = def.targetRelationField as keyof typeof model
    const relationId = model[relationField] as ModelId | undefined

    if (!relationId || !indexes[def.name]?.[relationId]) return

    const entry = indexes[def.name][relationId]
    updateRelatedIds(entry, model.id, 'remove')
    cleanupEmptyIndex(indexes, def.name, relationId)
  })

  updateCacheIndexes(client, indexes)
}

const CACHE_INDEXES_KEY = ['cache-indexes']

function getCacheIndexes(client: QueryClient): CacheIndexes {
  return client.getQueryData(CACHE_INDEXES_KEY) ?? ({} as CacheIndexes)
}

function updateCacheIndexes(client: QueryClient, indexes: CacheIndexes) {
  client.setQueryData(CACHE_INDEXES_KEY, indexes)
}

function getOrCreateIndexEntry(
  indexes: CacheIndexes,
  indexName: IndexName,
  relationId: ModelId
) {
  indexes[indexName] = indexes[indexName] ?? {}
  indexes[indexName][relationId] = indexes[indexName][relationId] ?? {
    relatedIds: [],
  }

  return indexes[indexName][relationId]
}

function cleanupEmptyIndex(
  indexes: CacheIndexes,
  indexName: IndexName,
  relationId: ModelId
) {
  const entry = indexes[indexName][relationId]
  if (entry.relatedIds.length === 0) {
    delete indexes[indexName][relationId]
  }
}

function updateRelatedIds(
  entry: { relatedIds: ModelId[] },
  modelId: ModelId,
  action: 'add' | 'remove'
) {
  const updatedIds = new Set(entry.relatedIds)
  if (action === 'add') {
    updatedIds.add(modelId)
  } else {
    updatedIds.delete(modelId)
  }
  entry.relatedIds = Array.from(updatedIds)
}

/**
 * If the model is being updated to a new relation, we need to remove it from the old relation's index.
 * @example
 * A task is being updated to a new project. We need to remove it from the old project's index.
 */
function handleOldRelation<TType extends keyof AllModelsSchema>(
  indexes: CacheIndexes,
  def: (typeof INDEX_DEFINITIONS)[number],
  model: Partial<Model<TType>> & { id: ModelId },
  relationField: keyof typeof model,
  update?: QueryCacheUpsert<keyof AllModelsSchema>
) {
  const oldRelationId = (update?.inverse as typeof model)?.[relationField] as
    | ModelId
    | undefined

  if (oldRelationId) {
    const indexEntry = indexes[def.name]?.[oldRelationId]
    if (indexEntry) {
      updateRelatedIds(indexEntry, model.id, 'remove')
      cleanupEmptyIndex(indexes, def.name, oldRelationId)
    }
  }
}
