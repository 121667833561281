import { classed } from '@motion/theme'

import React, { type ReactNode } from 'react'

import { type TabItem, TabList, type TabListProps } from './tab-list'

export type TabsProps = {
  value: string
  onChange: (value: string) => void
  children: ReactNode
  gap?: TabListProps['gap']
  outerPadding?: TabListProps['outerPadding']
}

export const Tabs = (props: TabsProps) => {
  const childrenItems = React.Children.toArray(
    props.children
  ) as React.ReactElement<TabProps>[]

  if (childrenItems.length === 0) {
    return null
  }

  const items = childrenItems.map<Exclude<TabItem[][number], false>>(
    (child) => {
      return {
        title: child.props.title,
        value: child.props.value,
        onAction: () => props.onChange(child.props.value),
        size: child.props.size,
      }
    }
  )

  const activeValue = props.value || items[0].value
  const activeTab =
    childrenItems.find((x) => x.props.value === activeValue) ?? childrenItems[0]

  return (
    <TabContainer>
      <TabList
        items={items}
        activeValue={activeValue}
        gap={props.gap}
        outerPadding={props.outerPadding}
      />
      <TabContent>{activeTab}</TabContent>
    </TabContainer>
  )
}

export type TabProps = Omit<TabItem, 'onAction' | 'url'> & {
  children: ReactNode
}

export const Tab = (props: TabProps) => {
  return <>{props.children}</>
}
Tab.displayName = 'Tab'

const TabContainer = classed('div', {
  base: 'grid grid-rows-[auto_1fr] overflow-hidden',
})
TabContainer.displayName = 'TabContainer'

const TabContent = classed('div', {
  base: 'text-semantic-neutral-text-default overflow-hidden',
})
TabContent.displayName = 'TabContent'
