type GetTaskStaticURLProps = {
  workspaceId: string
  taskId: string
  origin?: string
}
export const getTaskStaticURL = ({
  workspaceId,
  taskId,
  origin = window.location.origin,
}: GetTaskStaticURLProps) => {
  return buildUrl(workspaceId, { task: taskId }, origin)
}

export const getMeetingTaskStaticURL = ({
  workspaceId,
  taskId,
  origin = window.location.origin,
}: GetTaskStaticURLProps) => {
  return buildUrl(workspaceId, { mTask: taskId }, origin)
}

type GetProjectStaticURLProps = {
  workspaceId: string
  projectId: string
  origin?: string
}
export const getProjectStaticURL = ({
  workspaceId,
  projectId,
  origin = window.location.origin,
}: GetProjectStaticURLProps) => {
  return buildUrl(workspaceId, { project: projectId }, origin)
}

type GetAttachmentStaticURLProps = {
  workspaceId: string
  attachmentId: string
  taskId?: string
  projectId?: string
  origin?: string
}
export const getAttachmentStaticURL = ({
  workspaceId,
  attachmentId,
  taskId,
  projectId,
  origin,
}: GetAttachmentStaticURLProps) => {
  const args: Record<string, string> = { attachment: attachmentId }

  if (taskId) {
    args.task = taskId
  }
  if (projectId) {
    args.project = projectId
  }

  return buildUrl(workspaceId, args, origin)
}

const basePath = '/web/pm/workspaces/' as const

function buildUrl(
  workspaceId: string,
  args: Record<string, string>,
  origin = window.location.origin
) {
  const searchParams = new URLSearchParams(args)

  return new URL(`${basePath}${workspaceId}?${searchParams}`, origin).toString()
}
