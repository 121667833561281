import { filterAndRankMatches } from '@motion/ui-logic'

import { Fragment, type ReactNode, useMemo } from 'react'

import { type Node, type NodeWithMetadata } from './types'
import { getLeafNodesWithPath } from './utils'

type NodeSearchProps = {
  rootNode: Node
  search: string
  renderNode: (node: NodeWithMetadata) => ReactNode
}
export const NodeSearch = (props: NodeSearchProps) => {
  const { rootNode, search, renderNode } = props
  const filteredNodeLeafs = useMemo(() => {
    const leafNodes = getLeafNodesWithPath(rootNode)
    return filterAndRankMatches(search, leafNodes, (item) => item.label ?? '')
  }, [rootNode, search])

  if (filteredNodeLeafs.length === 0) {
    return (
      <div className='text-semantic-neutral-text-disabled text-xs text-center py-2'>
        No results
      </div>
    )
  }

  return (
    <>
      {filteredNodeLeafs.map((childNode) => (
        <Fragment key={childNode.id}>{renderNode(childNode)}</Fragment>
      ))}
    </>
  )
}
