import { CheckOutline, FilledChevronRightSolid } from '@motion/icons'

import { type PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

import { TreeNodeShell } from './tree-node-shell'

import { Button } from '../../../button'

type ExpandableTreeNodeProps = PropsWithChildren<{
  expandable: boolean
  isExpanded: boolean
  onExpandClick: () => void
  onSelect: () => void
  disabled?: boolean
  selected?: boolean
}>

export const ExpandableTreeNode = (props: ExpandableTreeNodeProps) => {
  const {
    expandable,
    isExpanded,
    children,
    onExpandClick,
    disabled = false,
    selected = false,
    onSelect,
  } = props

  return (
    <TreeNodeShell
      disabled={disabled}
      onClick={() => (expandable ? onExpandClick() : onSelect())}
    >
      {expandable && (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            onExpandClick()
          }}
          iconOnly
          size='small'
          variant='muted'
          sentiment='neutral'
          disabled={disabled}
        >
          <FilledChevronRightSolid
            className={twMerge(
              'text-semantic-neutral-text-disabled transition-all',
              isExpanded && 'rotate-90'
            )}
          />
        </Button>
      )}
      <div className='flex-grow flex truncate min-w-0'>{children}</div>
      {!expandable && selected && (
        <CheckOutline className='text-semantic-primary-icon-default w-4 h-4 shrink-0' />
      )}
    </TreeNodeShell>
  )
}
