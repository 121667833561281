import {
  type PriorityLevelType,
  type TemplateTaskType,
} from '@motion/rpc/types'
import { type CustomFieldValuesSchema } from '@motion/rpc-types'

import { type PMTeamTaskLabelType } from './PMLabelType'
import { type PMItemType, type PMProjectType } from './PMProjectType'
import { type PMTaskStatusType } from './PMTaskStatusType'

import { type UserType } from '../user'

export type DeadlineType = 'ASAP' | 'HARD' | 'SOFT' | 'NONE'

export type TaskScheduledStatus =
  | 'ON_TRACK'
  | 'PAST_DUE'
  | 'UNFIT_SCHEDULABLE'
  | 'UNFIT_PAST_DUE'

// All fields are optional in order to support partially created items
// i.e. create new item via UI and display it right away before the backend response comes back and replaces the temporary model
export interface PMTaskType {
  id: string
  name: string
  description?: string
  completedDuration?: number
  duration?: number | null
  dueDate?: string | null
  parentTask?: PMTaskType
  parentTaskId?: string
  priorityLevel: PriorityLevelType
  status?: PMTaskStatusType
  statusId: PMTaskStatusType['id']
  labels?: PMTeamTaskLabelType[]
  labelIds?: string[]
  sortPosition?: string
  /**
   * ISO Date
   */
  completedTime?: string | null
  project?: PMProjectType
  projectId?: PMProjectType['id'] | null
  assigneeUserId?: string | null
  assignee?: UserType | null
  /**
   * @deprecated Use `assigneeUserId` or `assignee` instead
   */
  assignees?: PMTaskAssigneeType[]
  /**
   * The tasks which block this task from being completed, i.e. "Blocked by"
   */
  blockingTasks?: PMTaskBlockerType[]
  /**
   * The tasks which this task blocks from being completed, i.e. "Blocks"
   */
  blockedTasks?: PMTaskBlockerType[]
  /**
   * ISO Date
   */
  createdTime: string
  updatedTime?: string | null
  archivedTime?: string | null
  createdByUserId: string
  creator?: UserType
  workspaceId: string
  minimumDuration?: number | null
  /**
   * ISO Date
   */
  scheduledStart?: string | null
  /**
   * ISO Date
   */
  scheduledEnd?: string | null
  /**
   * ISO Date. Important - should be treated as date
   */
  startDate?: string | null
  /**
   * ISO Date. Important - should be treated as date
   */
  startOn?: string | null
  isUnfit?: boolean // known as `unfit` in firebase
  type?: TaskType
  needsReschedule?: boolean
  providerId?: string
  rank?: string | null
  parentChunkTaskId?: string
  parentChunkTask?: PMTaskType
  chunks?: PMTaskType[]
  parentRecurringTaskId?: string
  recurrenceMeta?: string
  // Placed by frontend
  deadlineDiff?: number
  deadlineType?: DeadlineType
  sortKey?: string
  // Optionally placed by frontend
  itemType?: PMItemType
  isFixedTimeTask?: boolean
  schedule?: string | null
  /**
   * A list of task IDs of the chunks that have scheduled dates
   * Note: this field is calculated on the frontend
   * @deprecated
   */
  scheduledChunkIds?: string[]
  /**
   * For recurring instances, this signifies the latest the instance can be
   * scheduled before becoming 'unfit'
   */
  endDate?: string | null
  isBusy?: boolean

  isAutoScheduled: boolean
  ignoreWarnOnPastDue?: boolean
  scheduleOverridden?: boolean
  snoozeUntil?: string | null
  manuallyStarted?: boolean
  /**
   * @deprecated use `isTaskFutureSchedulable`
   */
  isFutureSchedulable?: boolean
  scheduledStatus?: TaskScheduledStatus | null
  /**
   * ISO Date
   */
  estimatedCompletionTime?: string | null

  customFieldValues?: CustomFieldValuesRecord

  stageDefinitionId?: string | null
  taskDefinitionId?: string | null

  isUnvisitedStage?: boolean
  isSyncingWithDefinition?: boolean
}

export type CustomFieldValuesRecord = {
  [instanceId: string]: CustomFieldValuesSchema
}

export type ProjectTemplateTask = TemplateTaskType['task'] & {
  templateProjectId: string
}

export interface PMTaskAssigneeType {
  id?: string
  taskId?: PMTaskType['id']
  task?: PMTaskType
  userId: string
  user?: UserType
}

export interface PMTaskBlockerType {
  id?: string
  blocking?: PMTaskType
  blockingId?: PMTaskType['id']
  blocked?: PMTaskType
  blockedId?: PMTaskType['id']
}

export type TaskType = keyof typeof TaskType
export const TaskType = {
  CHUNK: 'CHUNK',
  NORMAL: 'NORMAL',
  RECURRING_INSTANCE: 'RECURRING_INSTANCE',
  TEMPLATE: 'TEMPLATE',
} as const

export type PMTaskUpdate = Partial<PMTaskType> &
  Required<Pick<PMTaskType, 'id' | 'workspaceId'>>
