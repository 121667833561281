import { merge } from '@motion/utils/core'
import { keys } from '@motion/utils/object'
import {
  type AllModelsSchema,
  type NormalTaskSchema,
  type ProjectSchema,
} from '@motion/zod/client'

import {
  notifyManager,
  type QueryClient,
  type QueryFilters,
  type QueryKey,
} from '@tanstack/react-query'

import { createAppendNewTaskFilterFn, isTaskType } from './task-utils'

import {
  type CacheEntry,
  type Model,
  type ModelCacheCollection,
  type ModelId,
} from '../../model-cache'
import { log } from '../../utils'
import {
  type ModelRecord,
  type QueryCacheDelete,
  type QueryCacheMatches,
  type QueryCachePatch,
  type QueryCacheUpsert,
} from '../types'

export function matchQueries(client: QueryClient, filter: QueryFilters) {
  const queries = client.getQueriesData<QueryCacheMatches>(filter)
  log.debug('queries', queries)
  return queries
}

export function updateQueryData<TType extends keyof AllModelsSchema>(
  client: QueryClient,
  cacheUpdates:
    | QueryCacheUpsert<TType>[]
    | QueryCachePatch<TType>[]
    | QueryCacheDelete<TType>[]
) {
  const now = Date.now()
  log.time(`update query data`, () => {
    notifyManager.batch(() => {
      cacheUpdates.forEach((item) => {
        client.setQueryData(item.key, { ...item.data }, { updatedAt: now })
      })
    })
  })
}

export function shouldAppendNew<TType extends keyof AllModelsSchema>({
  key,
  ...taskWithType
}: {
  key: QueryKey
  type: TType
  model: Model<TType>
}) {
  /**
   * Only filter tasks.
   *
   * Add additional filters here as needed.
   */
  if (!isTaskType(taskWithType)) {
    return true
  }

  const appendNewTaskFilterFn = createAppendNewTaskFilterFn()

  const opt = appendNewTaskFilterFn(key)
  if (typeof opt === 'boolean') return opt

  return opt(taskWithType)
}

export function getResponseModelType(
  source: QueryCacheMatches
): keyof AllModelsSchema | null {
  if (!('meta' in source)) return null
  return source.meta.model
}

export function isModelRecordModelCacheCollection<
  TType extends keyof AllModelsSchema,
>(target: ModelRecord<TType>): target is ModelCacheCollection<Model<TType>> {
  if (target == null) return false

  // If no keys, it's not structured as a model cache collection
  if (Object.keys(target).length === 0) return false

  return Object.values(target).every(isModelCacheEntry)
}

export function buildDeletedInverse<TType extends keyof AllModelsSchema>(
  target: QueryCacheMatches,
  type: TType,
  id: ModelId
): Model<TType> | null {
  const modelRecord = target.models[type] as ModelRecord<TType>
  if (modelRecord == null || modelRecord[id] == null) return null

  return isModelRecordModelCacheCollection(modelRecord)
    ? modelRecord[id].value
    : modelRecord[id]
}

export function buildInverse<TType extends keyof AllModelsSchema>(
  target: Model<TType>,
  changes: Partial<Model<TType>>
): Partial<Model<TType>> {
  return keys(changes).reduce((acc, key) => {
    acc[key] = target[key]
    return acc
  }, {} as Model<TType>)
}

export const isModelCacheEntry = (obj: unknown): obj is CacheEntry<any> => {
  if (obj == null) return false
  if (typeof obj !== 'object') return false
  return 'updatedAt' in obj && 'value' in obj
}

export function isNormalTaskOrProject(
  target: object
): target is NormalTaskSchema | ProjectSchema {
  return 'type' in target && target.type === 'NORMAL'
}

export function mergeCacheData<TType extends keyof AllModelsSchema>(
  target: Model<TType>,
  updates: Partial<Model<TType>>
): Model<TType> {
  if (isNormalTaskOrProject(target)) {
    return mergeEntityWithCustomFields(target, updates) as Model<TType>
  }

  const data = merge(target, updates)

  return data
}

function mergeEntityWithCustomFields<
  T extends NormalTaskSchema | ProjectSchema,
>(target: T, updates: Partial<T>): T {
  const customFieldUpdates = mergeCustomFieldUpdates(target, updates)

  const data = {
    ...target,
    ...updates,
    ...(customFieldUpdates != null && Object.keys(customFieldUpdates).length > 0
      ? { customFieldValues: customFieldUpdates }
      : {}),
  }

  return data
}

export const mergeCustomFieldUpdates = (
  target: { customFieldValues: NormalTaskSchema['customFieldValues'] },
  updates: { customFieldValues?: NormalTaskSchema['customFieldValues'] }
): NormalTaskSchema['customFieldValues'] => {
  return {
    ...target.customFieldValues,
    ...updates.customFieldValues,
  }
}
