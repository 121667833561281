import {
  type PersistOptions,
  RpcProvider as BaseProvider,
  setLoaderContext,
} from '@motion/rpc'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type ReactNode, useEffect, useMemo } from 'react'

import { client } from './client'
import { STATIC_HEADERS } from './constants'
import { createQueryPersister } from './persister'

import {
  useAuth,
  useAuthToken,
  useHasCookieAuth,
} from '../auth/hooks/get-state'
import { useHasTreatment } from '../flags'
import { useLocalStorage } from '../storage'

type Props = {
  children: ReactNode
  persistKey?: string
}

declare module '../storage/local-storage/use-local-storage' {
  interface LocalStorageTypes {
    'motion:dev-tools': boolean
  }
}

declare module '@motion/web-common/flags/definitions' {
  export interface AmplitudeExperiments {
    'persisted-query-cache': { on: null; off: null }
  }
}

function usePersisterOptions(persistKey?: string): PersistOptions | undefined {
  const enabled = useHasTreatment('persisted-query-cache')

  return useMemo(() => {
    if (!enabled) return undefined
    return {
      persister: createQueryPersister(persistKey),
    }
  }, [enabled, persistKey])
}

export const ConnectedRpcProvider = (props: Props) => {
  const token = useAuthToken()
  const hasCookieAuth = useHasCookieAuth()
  const auth = useAuth()
  const persistOptions = usePersisterOptions(props.persistKey)

  const [devToolsVisible, setDevToolsVisible] = useLocalStorage(
    'motion:dev-tools',
    true
  )

  const devToolsEnabled = useHasTreatment('development-panel')

  if (!__IS_PROD__) {
    // @ts-expect-error - dev only
    window.toggleDevTools = () => setDevToolsVisible((prev) => !prev)
  }

  useEffect(() => {
    setLoaderContext(client, {
      token,
      baseUri: __BACKEND_HOST__,
      headers: STATIC_HEADERS,
    })
  }, [token])

  useEffect(() => {
    if (auth.auth.state === 'unauthenticated') {
      void persistOptions?.persister.removeClient()
    }
  }, [auth.auth.state, persistOptions?.persister])

  return (
    <BaseProvider
      client={client}
      token={hasCookieAuth ? undefined : () => Promise.resolve(token)}
      baseUri={__BACKEND_HOST__}
      headers={STATIC_HEADERS}
      persistOptions={persistOptions}
    >
      {devToolsEnabled && devToolsVisible && <ReactQueryDevtools />}
      {props.children}
    </BaseProvider>
  )
}
