// Mapped to backend values
export enum PMTeamMemberRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  GUEST = 'GUEST',
}

// For display purposes
export enum UserFriendlyRole {
  ADMIN = 'Admin',
  MEMBER = 'Team Member',
  GUEST = 'External Guest',
}
