import { type MetricSchema } from '@motion/rpc-types'
import { systemData } from '@motion/web-base/env'
import { logInDev, makeLog } from '@motion/web-base/logging'

import { onlineChanges } from './online'
import { normalizeSpanMark, type Span } from './span'
import { type Mark } from './types'
import { visibilityChanges } from './visibility'

import { STATIC_HEADERS } from '../rpc/constants'

const METRICS_URL = `${__BACKEND_HOST__}/metrics`
const BATCH_DELAY = 8_000

const log = makeLog('stats')

export type MetricMetadata = Pick<MetricSchema, 'log' | 'metadata'>

// Clears out start metrics if hot reload happens
if (import.meta.hot) {
  import.meta.hot.on('vite:beforeUpdate', (data) => {
    import.meta.hot!.data.wasHotReload = true
    logInDev('[vite]: Hot Reloading', data, import.meta.hot?.data)
    const now = performance.now()
    window._timings.htmlStart = now
    window._timings.loginMetricStatus = undefined
    performance.clearMarks('htmlStart')
    performance.clearMarks('extensionStart')
  })
}

const VISIBILITY_TAG = 'visibility'
const ONLINE_TAG = `online`

const populateTags = (userTags?: string[]) => {
  const tags = [
    ...(userTags ?? []),
    `web-version:${__SENTRY_RELEASE__ || 'unknown'}`,
    `web-env:${import.meta.env.MOTION_ENV}`,
    `source:${systemData.host}`,
  ]

  if (import.meta.env.MOTION_ENV === 'localhost') {
    tags.push(`boot:${import.meta.hot?.data.wasHotReload ? 'hot' : 'cold'}`)
  }

  return tags
}

function visibilityTag(startTime: number, endTime: number) {
  const visibility =
    visibilityChanges.ifNoChanges(startTime, endTime) ?? 'hidden'
  return `${VISIBILITY_TAG}:${visibility}`
}

function onlineTag(startTime: number, endTime: number) {
  const online = onlineChanges.ifNoChanges(startTime, endTime) ?? false
  return `${ONLINE_TAG}:${online}`
}

function systemTags(startTime: number, endTime: number) {
  return [visibilityTag(startTime, endTime), onlineTag(startTime, endTime)]
}

class StatsService {
  private queue: MetricSchema[] = []
  private timerId: NodeJS.Timeout | undefined
  private timingBaseline = 0

  private retryCount = 0
  private batchDelay = BATCH_DELAY

  constructor() {
    this.mark('htmlStart', [], window._timings.htmlStart)

    this.listenForInitialNavigate()
    this.listenForFirstFetch()
  }

  private listenForInitialNavigate() {
    const observer = new PerformanceObserver((entries) => {
      const items = entries.getEntries() as PerformanceNavigationTiming[]
      const last = items[items.length - 1]

      // If the page was navigated before the dom finishes loading then don't record
      if (last.duration === 0) {
        return
      }

      observer.disconnect()

      const additionalData =
        last.duration > 10_000 || last.duration - last.domInteractive < 0
          ? {
              log: true,
              metadata: {
                timing: {
                  ...last.toJSON(),
                  htmlStart: window._timings.htmlStart,
                },
                userAgent: window.navigator.userAgent,
                visibility: {
                  current: visibilityChanges.current.value,
                  initial: visibilityChanges.initialValue,
                },
              },
            }
          : {}

      this.timingBaseline = last.domInteractive
      this.mark('htmlStart', [], last.domInteractive)

      if (window._timings.htmlStart) {
        this.record({
          name: 'html_parse',
          type: 'distribution',
          value: last.domInteractive - window._timings.htmlStart,
          tags: systemTags(window._timings.htmlStart, last.domInteractive),
        })
      }

      this.record({
        name: 'dom_interactive',
        type: 'distribution',
        value: last.domInteractive - last.unloadEventEnd,
        tags: systemTags(last.unloadEventEnd, last.domInteractive),
      })

      this.record({
        name: 'dom_loaded',
        type: 'distribution',
        value: last.duration - last.domInteractive,
        tags: systemTags(last.domInteractive, last.duration),
        ...additionalData,
      })

      log('navigation', items)
    })
    observer.observe({ buffered: true, type: 'navigation' })
  }

  private listenForFirstFetch() {
    const resourceObserver = new PerformanceObserver((entries) => {
      const resources = entries.getEntries() as PerformanceResourceTiming[]
      const firstFetch = resources.find(
        (x) =>
          x.initiatorType === 'fetch' &&
          x.name.startsWith(__BACKEND_HOST__) &&
          !x.name.endsWith('/auth/cookie')
      )
      if (!firstFetch) return
      resourceObserver.disconnect()
      this.record({
        name: 'first_fetch',
        type: 'distribution',
        value: firstFetch.fetchStart - this.timingBaseline,
        tags: systemTags(this.timingBaseline, firstFetch.fetchStart),
      })
    })
    resourceObserver.observe({ buffered: true, type: 'resource' })
  }

  public mark(name: Mark, tags: string[] = [], timestamp = performance.now()) {
    logDuration('mark', timestamp, name)
    return performance.mark(name as string, {
      startTime: timestamp,
      detail: { visibility: visibilityChanges.current.value, tags },
    })
  }

  public getLatestMark(name: Mark) {
    const entries = performance.getEntriesByName(name as string, 'mark')
    return entries[entries.length - 1] as PerformanceMark | undefined
  }

  public getMarks(name: Mark) {
    return performance.getEntriesByName(
      name as string,
      'mark'
    ) as PerformanceMark[]
  }

  public hasMark(name: Mark) {
    return this.getLatestMark(name) != null
  }

  public time<T>(name: Mark, fn: () => T, tags?: string[]): T {
    const start = this.mark(name)
    const ret = fn()
    if (isPromise(ret)) {
      // @ts-expect-error - its fine
      return ret.finally(() => this.measure(name as string, start.name, tags))
    }
    this.measure(name as string, start.name, tags)
    return ret
  }

  public span(span: Span, meta?: MetricMetadata) {
    try {
      const start = normalizeSpanMark(span.start)
      const end = normalizeSpanMark(span.end)
      if (start == null || end == null) return

      const tags = [...(span.tags ?? []), ...systemTags(start, end)]
      const measure = performance.measure(span.name, {
        start,
        end,
        detail: { tags },
      })

      this.record({
        name: span.name,
        type: 'distribution',
        value: measure.duration,
        tags,
        ...meta,
      })

      return measure
    } catch {}
  }

  public measure(name: string, fromName: Mark, tags: string[] = []) {
    const namedMarks = performance.getEntriesByName(fromName as string, 'mark')
    if (namedMarks.length === 0) {
      log(
        `measure: Unable to measure '${name}'.  The from mark '${fromName}' was not found. Skipping`
      )
      return null
    }
    const lastMark = namedMarks[namedMarks.length - 1] as PerformanceMark
    if (lastMark.entryType !== 'mark') {
      log(
        `measure: Invalid fromMark [${fromName}]. Must be a 'mark', was ${lastMark.entryType}`
      )
      return
    }

    const entry = performance.measure(name, fromName as string)
    if (entry == null) return

    this.record({
      name,
      type: 'distribution',
      value: entry.duration,
      tags: [...tags, ...systemTags(lastMark.startTime, performance.now())],
    })

    return entry
  }

  public distribution(name: string, value: number, tags: string[] = []) {
    try {
      performance.measure(name, {
        duration: value,
        end: performance.now(),
        detail: tags,
      })
      const now = performance.now()
      this.record({
        name,
        type: 'distribution',
        value,
        tags: [...tags, ...systemTags(now - value, now)],
      })
    } catch {}
  }

  public increment(name: string, value: number = 1, tags: string[] = []) {
    this.record({
      name,
      type: 'increment',
      value,
      tags,
    })
  }

  public record(record: MetricSchema) {
    logDuration(record.type, record.value, record.name, record.tags)
    const stat = {
      ...record,
      log: Boolean(record.log),
      tags: populateTags(record.tags),
    }

    this.queue.push(stat)
    this.triggerPost()
  }

  flush() {
    this.timerId = undefined
    const local = this.queue
    this.queue = []
    return this.post(local).then((success) => {
      if (success) {
        return (this.retryCount = 0)
      }

      this.retryCount += 1
      this.queue.unshift(...local)
      this.triggerPost()
      return
    })
  }

  private triggerPost() {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }

    const delay = Math.max(
      20,
      Math.min(this.batchDelay * (this.retryCount + 1), 600_000)
    )

    this.timerId = setTimeout(() => {
      void this.flush()
    }, delay)
  }

  private post(records: MetricSchema[]): Promise<boolean> {
    return fetch(METRICS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...STATIC_HEADERS,
      },
      body: JSON.stringify(records),
    })
      .then(() => {
        log(`Pushed ${records.length} metrics`)
        return true
      })
      .catch((ex) => {
        log(`Failed to log stats.`, ex)
        return false
      })
  }
}

export const stats = new StatsService()

function logDuration(title: string, value: number, ...rest: any[]) {
  const formattedNumber = value.toFixed(2).padStart(8)
  log(title, `[${formattedNumber}]`, ...rest)
}

function isPromise<T>(value: T | Promise<T>): value is Promise<T> {
  if (value == null) return false
  // @ts-expect-error - will be fine
  return 'then' in value
}
