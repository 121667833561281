import { classed, type ComponentProps, type VariantProps } from '@motion/theme'

import { forwardRef } from 'react'

import { Link } from '../../internal'
import { Shortcut, type ShortcutProps } from '../shortcut'

const StyledButton = classed('button', {
  base: `
  flex gap-1.5 items-center justify-center
  rounded font-semibold text-xs
  [&_[data-icon]]:w-4
  [&_[data-icon]]:h-4
  px-2 py-1
  `,
  variants: {
    fullWidth: {
      true: 'w-full self-stretch',
    },
    sentiment: {
      primary: `
        box-border
        border border-solid border-[#314FB1]
        bg-[linear-gradient(180deg,#4D70E5_0%,#5E7FEB_0.01%,#395CD2_100%)]
      hover:border-[#25409D]
        hover:bg-[linear-gradient(180deg,#4D70E5_0%,#456CED_0.01%,#264CC9_100%)]

      active:border-[#1C368D]
        active:bg-[linear-gradient(180deg,#4D70E5_0%,#2856F0_0.01%,#1D41B9_100%)]
        text-button-primary-solid-text-default
        hover:text-button-primary-solid-text-default
        shadow-[0_2px_0_0_rgba(255,255,255,0.20)_inset,0px_1px_4px_0px_rgba(0,0,0,0.20)]
      `,
      promotion: `
        bg-promotion-gradient
        hover:bg-promotion-hover-gradient
        active:bg-promotion-active-gradient
        focus:border-button-neutral-outlined-border-focus
        border-radius: 4px;
        text-semantic-gradient-purple
        [&_[data-icon]]:[background-clip:text]
      `,
      purple: `
        bg-[linear-gradient(180deg,var(--motion-semantic-gradient-pink),var(--motion-semantic-gradient-purple))]
        active:border-button-primary-solid-border-focus
        rounded
        text-semantic-neutral-text-onDark
        [&_[data-icon]]:[background-clip:text]
      `,
    },
    disabled: {
      true: `
        border-button-neutral-outlined-border-disabled
        bg-button-neutral-outlined-bg-disabled
        text-button-neutral-outlined-text-disabled
        hover:bg-button-neutral-outlined-bg-disabled
        active:bg-button-neutral-outlined-bg-disabled
        cursor-not-allowed
      `,
    },
  },
  defaultVariants: {
    sentiment: 'primary',
  },
})

export type GradientButtonProps = Omit<
  ComponentProps<typeof StyledButton>,
  'className' | 'style'
> &
  VariantProps<typeof StyledButton> & {
    shortcut?: string
    renderShortcutTooltip?: ShortcutProps['renderTooltip']
    url?: string
  }

export const GradientButton = forwardRef<
  HTMLButtonElement,
  GradientButtonProps
>((props, ref) => {
  const { children, shortcut, renderShortcutTooltip, url, ...rest } = props

  return (
    <StyledButton
      ref={ref}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      as={url ? (Link as any) : undefined}
      {...rest}
      url={url}
    >
      {children}

      {shortcut != null && (
        <Shortcut
          shortcut={shortcut}
          renderTooltip={renderShortcutTooltip}
          sentiment={determineShortcutSentiment(props.sentiment)}
        />
      )}
    </StyledButton>
  )
})

GradientButton.displayName = 'GradientButton'

function determineShortcutSentiment(
  buttonSentiment: VariantProps<typeof StyledButton>['sentiment']
): ComponentProps<typeof Shortcut>['sentiment'] {
  switch (buttonSentiment) {
    case 'promotion':
      return 'default'
    default:
      return 'onDark'
  }
}
