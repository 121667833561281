import type { AllModelsSchema } from '@motion/rpc-types'
import { isEqual } from '@motion/utils/core'

import { ARCHIVED_TIME_KEY } from './constants'
import { type ApplyModelReturn, type ModelRecord } from './types'
import { buildInverse, isModelCacheEntry, mergeCacheData } from './utils'

import { type CacheEntry, type Model, type ModelId } from '../model-cache'

export function applyPartialToTargetStore<TType extends keyof AllModelsSchema>(
  targetStore: ModelRecord<TType>,
  id: ModelId,
  updates: Partial<Model<TType>>
): ApplyModelReturn<TType> {
  const now = Date.now()

  let target = targetStore[id]
  if (target == null) return { changed: false }

  const applied = applyPartial<TType>(target, updates)
  if (!applied.changed) {
    return applied
  }

  if (ARCHIVED_TIME_KEY in updates && updates[ARCHIVED_TIME_KEY] != null) {
    delete targetStore[id]
    return applied
  }

  if (isModelCacheEntry(target)) {
    targetStore[id] = {
      updatedAt: now,
      value: applied.data,
    }
  } else {
    targetStore[id] = applied.data
  }

  return applied
}

const applyPartial = <TType extends keyof AllModelsSchema>(
  target: Model<TType> | CacheEntry<Model<TType>>,
  updates: Partial<Model<TType>>
): ApplyModelReturn<TType> => {
  const value = isModelCacheEntry(target) ? target.value : target

  const inverse = buildInverse(value, updates)
  const hasChanges = !isEqual(updates, inverse)

  if (!hasChanges) {
    return { changed: false }
  }

  const data = mergeCacheData(value, updates)

  return {
    changed: true,
    inverse,
    data,
  }
}
