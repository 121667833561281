import { type COLOR } from '@motion/shared/common'
import { type ColorClassId } from '@motion/ui-logic'
import { entries } from '@motion/utils/object'

import { GRAY_HUE } from './palette-provider'

export function getColorHueValue(color: COLOR | string) {
  return colorOptions[color as COLOR]?.hue ?? GRAY_HUE
}

export function getColorName(color: COLOR | string): string {
  return colorOptions[color as COLOR]?.name ?? ''
}

export function getColorFromColorClassId(colorClassId: ColorClassId): COLOR {
  return colorClassIdToColor.get(colorClassId) ?? 'gray'
}

export function getColorClassIdForColor(color: COLOR): ColorClassId | null {
  return colorOptions[color].colorClassId
}

type ColorValue = {
  hue: number
  name: string
  colorClassId: ColorClassId | null
}

export const colorOptions: Record<COLOR, ColorValue> = {
  red: { hue: 0, name: 'Red', colorClassId: '5' },
  orange: { hue: 15, name: 'Orange', colorClassId: '11' },
  tangerine: { hue: 30, name: 'Tangerine', colorClassId: null },
  yellow: { hue: 45, name: 'Yellow', colorClassId: '7' },
  'highlighter-yellow': {
    hue: 60,
    name: 'Yellow Highlight',
    colorClassId: null,
  },
  lime: { hue: 75, name: 'Lime', colorClassId: null },
  mint: { hue: 90, name: 'Mint', colorClassId: '4' },
  green: { hue: 105, name: 'Green', colorClassId: '8' },
  sage: { hue: 135, name: 'Sage', colorClassId: null },
  emerald: { hue: 150, name: 'Emerald', colorClassId: '1' },
  cyan: { hue: 165, name: 'Cyan', colorClassId: '3' },
  teal: { hue: 180, name: 'Teal', colorClassId: null },
  sky: { hue: 195, name: 'Sky', colorClassId: null },
  blue: { hue: 210, name: 'Blue', colorClassId: '0' },
  cobalt: { hue: 225, name: 'Cobalt', colorClassId: null },
  lavender: { hue: 240, name: 'Lavender', colorClassId: '9' },
  purple: { hue: 255, name: 'Purple', colorClassId: null },
  violet: { hue: 270, name: 'Violet', colorClassId: '2' },
  grape: { hue: 285, name: 'Grape', colorClassId: null },
  pink: { hue: 315, name: 'Pink', colorClassId: '12' },
  raspberry: { hue: 330, name: 'Raspberry', colorClassId: null },
  rose: { hue: 345, name: 'Rose', colorClassId: '10' },
  gray: { hue: GRAY_HUE, name: 'Gray', colorClassId: '6' },
}

const colorClassIdToColor = new Map(
  entries(colorOptions).map(([color, { colorClassId }]) => [
    colorClassId,
    color,
  ])
)
