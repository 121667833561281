import {
  useMyUserSettings,
  useSavePostgresOnboardingSettings,
} from '@motion/web-common/settings'

import { useCallback } from 'react'

import { type PersistedOnboardingState } from '../../types'

export const usePersistedOnboardingStatePostgres = (opts: {
  read: boolean
  write: boolean
}): [
  PersistedOnboardingState | undefined,
  (data: Partial<PersistedOnboardingState>) => Promise<void>,
] => {
  const { mutateAsync: saveToPostgres } = useSavePostgresOnboardingSettings()

  const { data } = useMyUserSettings(undefined, {
    enabled: opts.read,
  })

  const update = useCallback(
    async (data: Partial<PersistedOnboardingState>) => {
      if (!opts.write) return
      await saveToPostgres(data)
    },
    [opts.write, saveToPostgres]
  )

  return [data?.onboarding, update] as const
}
