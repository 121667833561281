import { useState } from 'react'

import { type RecursiveTreeNodeProps } from './types'
import { isRootNode } from './utils'

export const RecursiveTreeNode = (props: RecursiveTreeNodeProps) => {
  const { node, renderNode, depth = -1 } = props
  const [isExpanded, setIsExpanded] = useState<boolean>(
    node.defaultExpanded === true && node.disabled !== true
  )

  const toggleExpanded = () => {
    if (node.disabled) return
    if (node.nodes != null) {
      setIsExpanded((prev) => !prev)
    }
  }

  // Skip rendering the root node
  if (isRootNode(node)) {
    return (
      <>
        {node.nodes.map((childNode) => (
          <RecursiveTreeNode
            key={childNode.id}
            node={childNode}
            renderNode={renderNode}
            depth={depth + 1}
          />
        ))}
      </>
    )
  }

  return (
    <>
      <div style={{ paddingLeft: 24 * depth }}>
        {renderNode(node, { isExpanded, toggleExpanded })}
      </div>
      {isExpanded &&
        node.nodes != null &&
        node.nodes.map((childNode) => (
          <RecursiveTreeNode
            key={childNode.id}
            node={childNode}
            renderNode={renderNode}
            depth={depth + 1}
          />
        ))}
    </>
  )
}
