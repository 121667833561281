import { useDependantState, useOnDomEvent } from '@motion/react-core/hooks'
import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

export type HoverRowProps = {
  children: ReactNode
  actions: ReactNode
  override?: 'show' | 'hide' | 'default'
  className?: string
  actionsCentered?: boolean
}

export const HoverRow = (props: HoverRowProps) => {
  const [override, setOverride] = useDependantState(() => {
    return props.override
  }, [props.override])

  const ref = useOnDomEvent('motion:popover', (event) => {
    const { state } = event.detail
    if (props.override && props.override !== 'default') return
    setOverride(state === 'open' ? 'show' : 'default')
  })

  return (
    <Row ref={ref} override={override} className={props.className}>
      <Body>{props.children}</Body>
      <ActionContainer override={override} centered={props.actionsCentered}>
        {props.actions}
      </ActionContainer>
    </Row>
  )
}

const Row = classed('div', {
  base: `
    grid 
    relative
    group
  `,
  variants: {
    override: {
      show: 'grid-cols-[1fr_auto]',
      hide: 'grid-cols-[1fr_0]',
      default: `
        grid-cols-[1fr_0]
        hover:grid-cols-[1fr_auto]
        focus:grid-cols-[1fr_auto]
        focus-within:grid-cols-[1fr_auto]
        active:grid-cols-[1fr_auto]
      `,
    },
  },
  defaultVariants: {
    override: 'default',
  },
  dataAttributes: ['override'],
})
const Body = classed('div', {
  base: `overflow-hidden`,
})

const ActionContainer = classed('div', {
  base: `
    inset-[0_0_0_auto]
    absolute
    opacity-0
    pointer-events-none
  `,
  variants: {
    override: {
      show: `
        relative opacity-100 pointer-events-auto
      `,
      hide: '',
      default: `
        group-hover:relative
        group-hover:opacity-100
        group-hover:pointer-events-auto

        group-focus:relative
        group-focus:opacity-100
        group-focus:pointer-events-auto

        group-focus-within:relative
        group-focus-within:opacity-100
        group-focus-within:pointer-events-auto

        group-active:relative
        group-active:opacity-100
        group-active:pointer-events-auto
      `,
    },
    centered: {
      true: 'flex items-center',
      false: '',
    },
  },
  defaultVariants: {
    override: 'default',
    centered: false,
  },
})
